<template>
  <section class="app-ecommerce-details">
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <!-- Content -->
    <b-card v-if="itemData" no-body>
      <b-badge
        variant="danger"
        class="p-1"
        style="font-size:15px;"
        v-if="!delCat"
        >Archived Data</b-badge
      >
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="mainImage"
                :alt="`Image of ${itemTrans_name}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>
          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>
              <span style="color:#7367F0;">{{ $t("Add_Product.Name") }} :</span>
              {{ itemTrans_name }} - {{ itemTrans_name2 }}
            </h4>
            <br />
            <h4><span style="color:#7367F0;">SKU: </span> {{ sku }}</h4>

            <!-- <b-card-text class="item-company mb-0">
                            <h4><span style="color:#7367F0;">Sale Price: </span>{{price}}</h4>
                        </b-card-text> -->
            <br />
            <!-- <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                            <h4><span style="color:#7367F0;">Rate:</span></h4>
                            <ul class="unstyled-list list-inline pl-1">
                                <li v-for="star in 5" :key="star" class="ratings-list-item mr-25">
                                    <feather-icon icon="StarIcon" size="18" :class="[{'fill-current': star <= average_rate}, star <= average_rate ? 'text-warning' : 'text-muted']" />
                                </li>
                            </ul>
                        </div> -->
            <!-- <br /> -->
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color:#7367F0;"
                  >{{ $t("Add_Product.Brand") }} :</span
                >
                <b-link
                  style="color:black"
                  :to="{ path: '/Show-Brand/' + brand_id }"
                >
                  {{ brandTrans_name }}
                </b-link>
              </h4>
            </b-card-text>
            <br />
            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color:#7367F0;"
                  >{{ $t("Add_Product.Category") }} :</span
                >
                <span  style="color: black">
                  {{ catTrans_name }}
                  </span>
              </h4>
            </b-card-text>
            <br />
            <!-- Product Short Description -->
            <h5>{{ $t("Add_Product.Short_Description") }} :</h5>
            <b-card-text
              class="ml-3"
              v-for="(item, index) in itemTrans"
              :key="index"
            > <p v-html="item.short_description"></p></b-card-text>
            <br />
            <!-- Avability -->
            <b-card-text v-if="itemData.status === 'enabled'"
              ><span style="color:#7367F0;"
                >{{ $t("Add_Product.status") }} </span
              >-
              <b-badge variant="success">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'disabled'"
              ><span style="color:#7367F0;"
                >{{ $t("Add_Product.status") }}
              </span>
              -
              <b-badge variant="danger">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'review'"
              ><span style="color:#7367F0;"
                >{{ $t("Add_Product.status") }}
              </span>
              -
              <b-badge variant="warning">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-if="itemData.status === 'archieved'"
              ><span style="color:#7367F0;"
                >{{ $t("Add_Product.status") }}
              </span>
              -
              <b-badge variant="dark">{{ itemData.status }}</b-badge>
            </b-card-text>
            <hr />
            <div v-if="delCat == true" class="d-flex flex-column flex-sm-row">
              <b-button
                block
                v-b-modal.modal-deactive
                v-show="itemData.status === 'enabled'"
                variant="outline-info"
                class="mr-1 mt-1"
              >
                Deactivate
              </b-button>
              <b-modal
                id="modal-deactive"
                cancel-variant="outline-secondary"
                @ok="deActiveProduct(itemData.id)"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Deactivate Product"
              >
                <b-form>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="deactive"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                  >
                    Force Deactive
                  </b-form-checkbox>
                </b-form>
              </b-modal>
              <b-button
                block
                v-b-modal.modal-delete
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mr-1 mt-1"
              >
                <feather-icon icon="DeleteIcon" class="mr-50" />
                <span>{{ $t("Add_Product.Delete_Product") }}</span>
              </b-button>
              <b-modal
                id="modal-delete"
                cancel-variant="outline-secondary"
                @ok="deleteProduct(itemData.id)"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Delete Product"
              >
                <b-form>
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="deletedProduct"
                    name="checkbox-2"
                    value="1"
                    unchecked-value="0"
                  >
                    Force Delete
                  </b-form-checkbox>
                </b-form>
              </b-modal>
            </div>

            <hr />
            <div
              v-if="delCat"
              class="d-flex justify-content-around text-center"
            >
              <div class="date-section ">
                <h6 class="text-muted font-weight-bolder">
                  {{ $t("Show_Vendor.Created_at") }} :
                </h6>
                <p class="mb-0">
                  {{ itemData.created_at }}
                </p>
              </div>
              <div class="date-section">
                <h6 class="text-muted font-weight-bolder">
                  {{ $t("Show_Vendor.Updated_at") }} :
                </h6>
                <p class="mb-0">
                  {{ itemData.updated_at }}
                </p>
              </div>
            </div>
            <div v-else class="d-flex justify-content-around text-center">
              <div class="date-section ">
                <h6 class="text-muted font-weight-bolder">
                  {{ $t("Show_Vendor.Created_at") }} :
                </h6>
                <p class="mb-0">
                  {{ itemData.created_at }}
                </p>
              </div>
              <div class="date-section">
                <h6 class="text-muted font-weight-bolder">
                  {{ $t("Show_Vendor.Updated_at") }} :
                </h6>
                <p class="mb-0">
                  {{ itemData.deleted_at }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-row>
      <b-col cols="12">
        <b-card :title="$t('Add_Product.Variants')">
          <b-tabs style="overflow: auto">
            <b-tab @click="trashed = false" title="Arabic">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ $t("Add_Product.Image") }}</th>
                    <th scope="col">{{ $t("Add_Product.Name") }}</th>
                    <!-- <th scope="col">condition</th> -->
                    <th scope="col">{{ $t("Add_Product.Original_Price") }}</th>
                    <th scope="col">{{ $t("Add_Product.Profit") }}</th>
                    <th scope="col">{{ $t("Add_Product.Sale_Price") }}</th>
                    <th scope="col">{{ $t("Add_Product.Price") }}</th>
                    <th scope="col">{{ $t("Add_Product.Quantity") }}</th>
                    <!-- <th scope="col">Images</th> -->
                    <th scope="col">{{ $t("Add_Product.Stock") }}</th>
                    <th scope="col">{{ $t("Add_Product.Actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(i, n) in varAR" :key="n">
                    <th scope="row">{{ i.id }}</th>
                    <td>
                      <b-avatar :src="i.cover" />
                    </td>
                    <td>{{ i.name }}</td>
                    <!-- <td>{{i.condition.name.ar}}</td> -->
                    <td>{{ i.original_price }}</td>
                    <td>{{ i.profit }}</td>
                    <td>{{ i.sale_price }}</td>
                    <td>{{ i.price }}</td>
                    <td>{{ i.quantity }}</td>
                    <td>
                      <div v-if="(i.stock_status = 'in-stock')">
                        <b-badge variant="success">
                          في المخزن
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge variant="danger">لا يوجد</b-badge>
                      </div>
                    </td>
                    <td class="text-center p-0">
                      <!-- show button item -->
                      <b-button
                        block
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        class="p-0 py-1"
                        :to="{ path: '/New/ShowProduct/variant/show/' + i.id }"
                      >
                        <feather-icon icon="EyeIcon" class="" />
                        <span> {{ $t("Add_Product.Show_Variant") }}</span>
                      </b-button>
                      <!-- <b-button style='padding:7px; margin:6px; width:35px; height:35px;' variant="success" class="btn-icon rounded-circle" size="sm" :to="{path:'/New/ShowProduct/Variant/' +  i.sku, query: {slug: i.slug} }">
                                                <feather-icon style="width:18px; height:18px;" icon="EyeIcon" />
                                            </b-button> -->
                      <!-- update button item -->
                      <b-button
                        style="padding:7px; margin:6px; width:35px; height:35px;"
                        :to="{
                          path: '/New/ShowProduct/update/variant/' + i.id,
                        }"
                        variant="warning"
                        class="btn-icon rounded-circle"
                      >
                        <feather-icon
                          icon="EditIcon"
                          style="width:18px; height:18px;"
                        />
                      </b-button>
                      <!-- delete button item -->
                      <b-button
                        style="padding:7px; margin:6px; width:35px; height:35px;"
                        class="btn-icon rounded-circle"
                        size="sm"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        type="submit"
                        @click="deleteVariant(i.id)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          style="width:18px; height:18px;"
                        />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
          </b-tabs>

          <b-button
            variant="success"
            :to="{ path: '/New/ShowProduct/create/variant/' + itemData.id }"
            block
            class="mt-2"
          >
            {{ $t("Add_Product.Add_New_Variants") }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <!-- tags section -->
    <b-card :title="$t('Add_Product.Tag')">
      <div
        v-for="(tag, index) in itemData.tags"
        class="d-inline p-2"
        :key="index"
      >
        <span style="font-size: 17px;"
          ><b-badge
            variant="light-primary"
            style="padding:10px;"
            class="badge-glow badge-lg"
            >{{ tag.name }}</b-badge
          ></span
        >
      </div>
      <div></div>
    </b-card>
    <!-- Description section -->
    <b-card :title="$t('Add_Product.Description')">
      <b-tabs align="center" class="text-center">
        <b-tab
          v-for="(translation, index) in itemTrans"
          :title="translation.locale == 'en' ? 'English' : 'Arabic'"
          :key="index"
        >
          <b-card-text ><p v-html="translation.description"></p></b-card-text>

          <b-button
            v-if="translation.locale != 'en' && translation.locale != 'ar'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="danger"
            class="btn-cart ml-1"
            @click="deleteTranslation(translation.id)"
          >
            <feather-icon icon="DeleteIcon" class="mr-50" />
            <span>Delete {{ translation.locale }} translation text</span>
          </b-button>
        </b-tab>
      </b-tabs>
      <!-- add new translation -->
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="success"
        block
        class="btn-cart  mt-3"
        @click="
          $router.push({
            path: '/New/Product/addNewTranslation/' + itemData.id,
          })
        "
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span>{{ $t("Add_Product.Add_New_Translation") }}</span>
      </b-button>
    </b-card>
    <!-- Vendor Show -->
    <b-row class="d-flex justify-content-center" style="margin-top: 90px;">
      <b-col md="4">
        <b-card
          img-src=""
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar :src="vendorcover" size="114" variant="light" />
            </div>
          </div>
          <h2>
            <span style="color:#7367F0;">{{
              $t("Add_Product.Vendor_Information")
            }}</span>
          </h2>
          <h3>
            <b-link
              v-if="ven_type == 'agency'"
              style="color:black"
              :to="{ path: '/Vendor/Agency/Show/' + ven_id }"
              >{{ VendName }}
            </b-link>
            <b-link
              v-else
              style="color:black"
              :to="{ path: '/Vendor/Seller/Show/' + ven_id }"
              >{{ VendName }}
            </b-link>
          </h3>
          <h6 class="text-muted">
            {{ storename }}
          </h6>
          <b-badge class="profile-badge" variant="light-primary">
            {{ vendorType }}
          </b-badge>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
          </div>
          <hr class="mb-2" />
          <!-- follower projects rank -->
        </b-card>
      </b-col>
      <!-- Parent Product Show -->
      <b-col md="4" v-if="parentSet">
        <b-card
          img-src=""
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile "
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar :src="parentCover" size="114" variant="light" />
            </div>
          </div>
          <h2><span style="color:#7367F0;">Parent Product</span></h2>
          <h3>
            <b-link
              v-if="ven_type == 'agency'"
              style="color:black"
              :to="{ path: '/New/ShowProduct/' + parentID }"
              >{{ PhGuide }}
            </b-link>
          </h3>
          <h6 class="text-muted">
            {{ ShortDesc2 }}
          </h6>
          <b-badge class="profile-badge" variant="light-primary">
            {{ parentSKU }}
          </b-badge>
          <hr class="mb-2" />
          <!-- follower projects rank -->
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
          </div>
        </b-card>
      </b-col>
      <!-- Phone Guide Show -->
      <b-col md="4">
        <b-card
          img-src=""
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar size="114" variant="light" :src="phonegudieCover" />
            </div>
          </div>
          <h2>
            <span style="color:#7367F0;">{{
              $t("Add_Product.Phone_Guide")
            }}</span>
          </h2>
          <h3>
            <b-link
              v-if="ven_type == 'agency'"
              style="color:black"
              :to="{ path: '/ShowPhoneGuide/' + p_ID }"
              >{{ Pname }}
            </b-link>
          </h3>
          <p class="text-muted">
            {{ Pdesc }}
          </p>
          <b-badge class="profile-badge" variant="light-primary">
            {{ aRate }}
          </b-badge>
          <hr class="mb-2" />
          <!-- follower projects rank -->
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- Shipping Data Show -->
    <b-row style="margin-top: 50px;">
      <b-col md="12">
        <b-card class="text-center">
          <h3>
            <span style="color:#7367F0;">{{
              $t("Add_Product.Shipping_Data")
            }}</span>
          </h3>
          <h6 class="text-muted"></h6>
          <b-badge class="profile-badge" variant="light-primary"> </b-badge>
          <hr class="mb-6" />
          <!-- follower projects rank -->
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Add_Product.Length") }}
              </h6>
              <h3 class="mb-0">
                {{ length }}
              </h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Add_Product.Width") }}
              </h6>
              <h3 class="mb-0">
                {{ width }}
              </h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Add_Product.Height") }}
              </h6>
              <h3 class="mb-0">
                {{ height }}
              </h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Add_Product.Weight") }}
              </h6>
              <h3 class="mb-0">
                {{ weight }}
              </h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Add_Product.Distance_Unit") }}
              </h6>
              <h3 class="mb-0">
                {{ distance_unit }}
              </h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Add_Product.Mass_Unit") }}
              </h6>
              <h3 class="mb-0">
                {{ mass_unit }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- photo gallery section -->
    <CoolLightBox
      :items="photoGallery"
      :index="imgIndex"
      @close="imgIndex = null"
    >
    </CoolLightBox>
    <b-card :title="$t('Add_Product.Media')">
      <b-row>
        <b-col v-if="videoSect" cols="12">
          <h2>Video</h2>
          <video-player
            class="video-player-box"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            customEventName="customstatechangedeventname"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @statechanged="playerStateChanged($event)"
            @ready="playerReadied"
          >
          </video-player>
        </b-col>
        <b-col class="mt-2" cols="12">
          <h2>{{ $t("Add_Product.Photo_gallery") }}</h2>
          <swiper
            class="swiper-autoplay"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide v-for="(data, index) in photoGallery" :key="index">
              <b-img
                @click="imgIndex = index"
                width="150px"
                height="200px"
                :src="data"
                style="display:block;margin:auto;"
              />
              <!-- <div style="display: flex; align-items: center; justify-content: center;">
            
                                                                                                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon mt-1" :to="{name: 'EditImage',params:{imgID:data.id,image:data.src, desc:data.alt}}">
            
                                                                                                                Update
            
                                                                                                            </b-button>
            
                                                                                                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon ml-1 mt-1" @click="deleteImages(data.id)">
            
                                                                                                                Delete
            
                                                                                                            </b-button>
            
                                                                                                        </div> -->
            </swiper-slide>
            <div slot="pagination" class="swiper-pagination" />
          </swiper>
          <!-- add new image -->
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import TrashedVariantsTable from "@/Components/TrashedVariants";
import PrdouctsTable from "@/Components/PrdouctsTable";
import AppEchartLine from "@core/components/charts/echart/AppEchartLine.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import ECommerceProductDetailsRelatedProducts from "./ECommerceProductDetailsRelatedProducts.vue";
export default {
  name: "MyComponent",
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  components: {
    TrashedVariantsTable,
    ECommerceProductDetailsRelatedProducts,
    CoolLightBox,
    AppEchartLine,
    Swiper,
    SwiperSlide,
    PrdouctsTable,
  },
  data() {
    return {
      options: [],
      deactive: "0",
      deletedProduct: "0",
      videoSect: false,
      trashed: false,
      playerOptions: {
        // videojs options
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        poster: "",
      },
      related: [],
      relatedParent: false,
      varAR: [],
      varEN: [],
      p_ID: 0,
      ven_id: 0,
      ven_type: "",
      brand_id: 0,
      cat_id: 0,
      delCat: null,
      itemIDV: "",

      parentSet: true,
      showImg: false,
      imgIndex: null,
      mainImage: "",
      id: 0,
      itemData: {},
      itemComments: [],
      errors_back: [],
      showDismissibleAlert: false,
      itemTrans: "",
      itemDesc: null,
      itemTrans_name: null,
      itemTrans_name2: null,
      catTrans: null,
      catTrans_name: null,
      brandTrans: null,
      brandTrans_name: null,
      taxonomies: [],
      Catactive: [],
      price: "",
      active: "",
      ShortDesc: "",
      ShortDesc2: "",
      ShortDesc3: "",
      parentSKU: "",
      parentID: 0,
      sku: "",
      average_rate: "",
      VendName: "",
      vendorType: "",
      PhGuide: "",
      PhGuide_Desc: "",
      vendorcover: "",
      parentCover: "",
      phonegudieCover: "",
      VendCode: "",
      length: "",
      width: "",
      height: "",
      weight: "",
      mass_unit: "",
      distance_unit: "",
      storename: "",
      address: "",
      quantity: "",
      Pdesc: "",
      Pname: "",
      aRate: "",
      variants: null,
      loadMore: true,
      option: {
        xAxisData: [],
        series: [],
      },
      photoGallery: [],
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
      },
      items: [],
      stickyHeader: true,
      noCollapse: true,
      fields: [
        {
          key: "id",
          stickyColumn: true,
          isRowHeader: true,
          variant: "primary",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "description",
          label: "Description",
        },
        {
          key: "created_at",
          label: "Date",
        },
        {
          key: "actions",
        },
      ],
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      currentPage: 0,
      restoreSelected: {
        variants: [],
      },
      currentPage2: 0,
      options2: [],
      restoreSelected2: {
        variants: [],
      },
    };
  },
  created() {
    this.showDismissibleAlert = false;
    this.id = this.$route.params.id;
    this.fetchData();
    // this.getAllVariantsInSystem()
    // this.getAllTrashedVariantsInSystem()
  },
  methods: {
    fetchData() {
      axios
        .get("products/" + this.id)
        .then((result) => {
          this.varEN = [];
          this.varAR = [];
          this.variants = [];
          this.itemData = result.data.data;
          if (this.itemData.deleted_at != null) {
            this.delCat = false;
          } else {
            this.delCat = true;
          }
          this.itemIDV = this.itemData.id.toString();
          this.price = this.itemData.price + " EGP";
          if (this.itemData.translation.length > 0) {
            this.ShortDesc = this.itemData.translation[0].short_description;
            this.itemTrans = this.itemData.translation;
            this.itemTrans_name = this.itemData.translation[0].name;
          }
          this.sku = this.itemData.sku;
          this.average_rate = this.itemData.average_rate;
          if (this.itemData.category.translation.length > 0) {
            this.catTrans_name = this.itemData.category.translation[0].name;
          }
          this.brand_id = this.itemData.brand.id;
          this.cat_id = this.itemData.category.id;
          if (this.itemData.brand.translation.length > 0) {
            this.brandTrans_name = this.itemData.brand.translation[0].name;
          }
          this.VendName =
            this.itemData.vendor.firstname +
            " " +
            this.itemData.vendor.lastname;
          this.VendCode = this.itemData.vendor.code;
          if (this.itemData.parent.length > 0) {
            this.parentID = this.itemData.parent[0].id;
            if (this.itemData.parent[0].translation != null) {
              this.PhGuide = this.itemData.parent[0].translation.name;
              this.ShortDesc2 = this.itemData.parent[0].translation.short_description;
            }
            if (this.itemData.parent[0].Media.length > 0) {
              this.parentCover = this.itemData.parent[0].Media[0].name;
            }
            this.parentSKU = this.itemData.parent[0].sku;
            this.aRate = this.itemData.parent[0].average_rate;
            this.parentSet = true;
          } else {
            this.parentSet = false;
          }
          this.ven_id = this.itemData.vendor.id;
          this.ven_type = this.itemData.vendor.type;
          this.vendorcover = this.itemData.vendor.profile_picture;
          this.vendorType = this.itemData.vendor.type;
          this.phonegudieCover = this.itemData.phoneGuide.cover;
          this.p_ID = this.itemData.phoneGuide.id;
          if (this.itemData.shipping != null) {
            this.length = this.itemData.shipping.length;
            this.width = this.itemData.shipping.width;
            this.height = this.itemData.shipping.height;
            this.weight = this.itemData.shipping.weight;
            this.distance_unit = this.itemData.shipping.distance_unit;
            this.mass_unit = this.itemData.shipping.mass_unit;
          }
          this.storename = this.itemData.vendor.storename;
          this.address = this.itemData.vendor.address;
          this.quantity = this.itemData.quantity;
          this.phname = this.itemData.phoneGuide.name;
          if (this.itemData.phoneGuide.item_translations.length > 0) {
            this.Pname = this.itemData.phoneGuide.item_translations[0].name;
            this.Pdesc = this.itemData.phoneGuide.item_translations[0].short_description;
          }
          this.variants = this.itemData.variants;
          for (let ind in this.variants) {
            if (this.variants[ind].cover != null) {
              this.variants[ind].cover = this.variants[ind].cover;
            } else {
              this.variants[ind].cover = "";
            }
          }
          this.mainImage = this.itemData.cover;
          if (this.itemData.Media.length > 0) {
            for (let img in this.itemData.Media) {
              if (this.itemData.Media[img].type != "video") {
                this.photoGallery.push(this.itemData.Media[img].name);
              }
            }
          }
          for (let vid in this.itemData.Media) {
            if (this.itemData.Media[vid].type == "video") {
              this.videoSect = true;
              this.playerOptions.sources[0].src = this.itemData.Media[vid].name;
            }
          }
          for (let ind in this.variants) {
            this.variants[ind].cover2 = this.variants[ind].cover.name;
            if (this.variants[ind].translation.length > 0) {
              for (let tran in this.variants[ind].translation) {
                if (this.variants[ind].translation[tran].locale == "en") {
                  this.varEN.push(this.variants[ind]);
                  this.varEN[ind].name = this.variants[ind].translation[
                    tran
                  ].name;
                  this.varEN[ind].slug = this.variants[ind].translation[
                    tran
                  ].slug;
                  // this.variants[ind].nameEN = this.variants[ind].translation[tran].name
                  // this.variants[ind].slugEN = this.variants[ind].translation[tran].slug
                } else {
                  this.varAR.push(this.variants[ind]);
                  this.varAR[ind].name = this.variants[ind].translation[
                    tran
                  ].name;
                  this.varAR[ind].slug = this.variants[ind].translation[
                    tran
                  ].slug;
                  // this.variants[ind].nameAR = this.variants[ind].translation[tran].name
                  // this.variants[ind].slugAR = this.variants[ind].translation[tran].slug
                }
              }
            }
          }
          if (this.itemData.parent.length > 0) {
            axios
              .get("products/" + this.parentID + "/grouped_products")
              .then((result) => {
                const data = result.data.data.groupedProduct;
                for (let ind in data) {
                  if (data[ind].translation != null) {
                    this.related.push({
                      name: data[ind].translation.name,
                      price: data[ind].price,
                      rating: data[ind].average_rate,
                      brand: this.brandTrans_name,
                      image: data[ind].cover.name,
                    });
                  } else {
                    this.related.push({
                      name: "undefinded",
                      price: data[ind].price,
                      rating: data[ind].average_rate,
                      brand: this.brandTrans_name,
                      image: data[ind].cover,
                    });
                  }
                }
              })
              .catch((err) => {
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    getAllTrashedVariantsInSystem($state) {
      this.currentPage2 += 1;

      axios
        .get(
          "products/" +
            this.id +
            "/variants/trashed/25/sortBy-id-desc?page=" +
            this.currentPage2
        )
        .then((result) => {
          const variants = result.data.data.data;
          this.loadMore = false;
          if (variants.length > 0) {
            for (let index in variants) {
              if (variants[index].translation != null) {
                this.options2.push({
                  text: variants[index].translation.name,
                  value: variants[index].id.toString(),
                });
              } else {
                this.options2.push({
                  text: "undefinded",
                  value: variants[index].id.toString(),
                });
              }
            }

            $state.loaded();
          } else {
            $state.complete();
          }
          this.loadMore = false;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    resteData() {
      this.currentPage2 = 0;
      this.options2.length = 0;
      this.restoreSelected2.variants.length = 0;
    },
    getAllVariantsInSystem($state) {
      this.currentPage += 1;
      axios
        .get(
          "products/" +
            this.id +
            "/variants/25/sortBy-id-desc?page=" +
            this.currentPage
        )
        .then((result) => {
          const variants = result.data.data.data;
          this.loadMore = false;
          if (variants.length > 0) {
            for (let index in variants) {
              if (variants[index].translation != null) {
                this.options.push({
                  text: variants[index].translation.name,
                  value: variants[index].id.toString(),
                });
              } else {
                this.options.push({
                  text: "undefinded",
                  value: variants[index].id.toString(),
                });
              }
            }
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    deleteVariant(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete variant.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("variants/" + id + "/delete")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deleteProduct(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete product.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete(
                "actions/products/" +
                  this.id +
                  "/archive/" +
                  this.deletedProduct
              )
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    // listen event
    onPlayerPlay(player) {},
    onPlayerPause(player) {},
    // ...player event
    // or listen state event
    playerStateChanged(playerCurrentState) {},
    // player is ready
    playerReadied(player) {
      // you can use it to do something...
      // player.[methods]
    },
    deleteTranslation(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete translation.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("products-guide-translations/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deleteImages(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete image.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("products-guide-media/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deleteReview(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete comment.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("products-guide/review/product/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },

    deActiveProduct(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to deactivate product.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "info",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("actions/products/" + id + "/deactive/" + this.deactive)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },

    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>

<style>
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px !important;
}
.swiper-container {
  padding: 30px !important;
}
[dir] .video-js {
  width: 100% !important;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
[dir="rtl"] .video-js .vjs-big-play-button {
  right: auto !important;
  top: auto !important;
}
</style>
